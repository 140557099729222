import React from "react"

import Header from "./header"
import "../styles/layout.css"
import { StaticImage } from "gatsby-plugin-image"

type layoutProps = {
    title: string
}

const Layout: React.FC<layoutProps> = ({ children, title}) => (
    <>
        <Header siteTitle={title}/>
        <div className="m-auto max-w-4xl first-div p-6 normal-font">
            <main>{children}</main>
            <footer className="mt-2 buttons are-small flex content-center">
                © {new Date().getFullYear()}, Built with
                {` `}
                <a href="https://www.gatsbyjs.com" className="ml-3 mt-2 button is-white">
                    <span className="icon is-small">
                        <StaticImage src="../images/gatsby-icon.png" alt="gatsby-icon"/>
                    </span>
                    <span>
                        Gatsby
                    </span>
                </a>
            </footer>
        </div>
    </>
)

export default Layout