import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

type headProp = {
    siteTitle: string
}

const Header: React.FC<headProp> = ({ siteTitle }) => {
    const [isActive, setisActive] = React.useState(false);

    return (
        <header
            className="mb-6"
        >
            <Helmet>
                <title>Alfonsocvu</title>

                <meta /* Base metas */ name="title" content="Alfonsocvu" />
                <meta name="description" content="My presentation/portfolio page" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                <meta /* MSG apps */ name="msapplication-TileColor" content="#000000" />
                <meta name="msapplication-config" content="/favicon/browserconfig.xml" />

                <meta /* Twitter */ name="twitter:site" content="@mmp.alfonsocvu.me" />
                <meta name="twitter:creator" content="alfonso102" />
                <meta name="twitter:card" content="summary_large_image" />

                <meta /* Open Graph */ property="og:type" content="website" />
                <meta property="og:url" content="https:/alfonsocvu.me/" />
                <meta property="og:site_name" content="Alfonsocvu" />
                <meta property="og:title" content="Alfonsocvu" />
                <meta property="og:description" content="My presentation/portfolio page" />
                <meta property="og:image" content="https://alfonsocvu.me/logo.png" />
            </Helmet>
            <nav className="navbar m-auto max-w-4xl md:px-6 pt-6 normal-font" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">

                    <div className="navbar-item">
                        <Link className="navbar-brand remove-a-decoration hover:bg-gray-50" to="/">
                            <div className="navbar-item">
                                <h1 className="m-0 hidden md:flex">Alfonso Villalobos</h1>
                                <h1 className="m-0 flex ml-4 lg:hidden md:hidden">Alfonso</h1>
                            </div>
                        </Link>
                    </div>

                    <a onClick={() => {
                        setisActive(!isActive);
                    }}
                        role="button"
                        className={`navbar-burger burger mr-4 mt-4 ${isActive ? "is-active" : ""}`}
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
                <div id="navbarBasicExample" className={`navbar-menu text-sm mt-4 ${isActive ? "is-active" : ""}`}>
                    <div className="navbar-end">
                        <Link className="navbar-item remove-a-decoration button is-white" to="/about">About</Link>
                        <Link className="navbar-item remove-a-decoration button is-white" to="/blog">blog</Link>
                        <Link className="navbar-item remove-a-decoration button is-white" to="/contact">Contact</Link>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header