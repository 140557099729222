/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';
import './src/styles/configBulma.scss';

import React from "react"
import Layout from "./src/components/layout"
import { MDXProvider } from '@mdx-js/react'

const components = {
    wrapper: props => (
        <Layout>
            <div className="-mt-16 mb-2">
                <main {...props} />
            </div>
        </Layout>
    )
}

export const wrapRootElement = ({ element })=> (
    <MDXProvider components={components}>
        {element}
    </MDXProvider>
)